<template>
  <a-layout style="height: 100%" v-if="authStatus">
    <a-spin tip="Loading..." :spinning="loading">
      <a-layout-header class="header">
        <a-layout>
          <a-layout-content style="display: flex; height: inherit;" class="header-wrapper">
            <a-menu
                theme="dark"
                mode="horizontal"
                :style="{ lineHeight: '64px', flex: 1 }"
            >
              <a-menu-item key="dashboard" :class="{'ant-menu-item-selected': isMenuItemActive(['general'])}">
                <router-link :to="{name:'admin'}" v-if="user">General</router-link>
              </a-menu-item>
              <a-menu-item key="users" :class="{'ant-menu-item-selected': isMenuItemActive(['users','user'])}">
                <router-link :to="{name:'users'}" v-if="user">Users</router-link>
              </a-menu-item>
              <a-menu-item key="admins" :class="{'ant-menu-item-selected': isMenuItemActive(['admins'])}">
                <router-link :to="{name:'admins'}" v-if="user">Admins</router-link>
              </a-menu-item>
              <a-menu-item key="settings" :class="{'ant-menu-item-selected': isMenuItemActive(['settings'])}">
                <router-link :to="{name:'settings'}" v-if="user">Settings</router-link>
              </a-menu-item>
            </a-menu>
            <div class="actions" >
              <a @click="loginAsDemoUser" style="display: contents">
                <a-tooltip title="Login As Demo User">
                  <UserOutlined style="color:#fff"/>
                </a-tooltip>
              </a>
              <a @click="loginAsTemplateConstructorAdmin" style="display: contents">
                <a-tooltip title="Login As Template Constructor Admin">
                  <UserOutlined style="color:#fff"/>
                </a-tooltip>
              </a>
              <a @click="logOut" style="display: contents">
                <a-tooltip title="Logout">
                  <LogoutOutlined style="color:#fff"/>
                </a-tooltip>
              </a>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout-header>
      <a-layout>
        <a-layout>
          <a-layout-content
              :style="{ background: '#fff', margin: 0, minHeight: '280px' }"
          >
            <router-view v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-spin>
  </a-layout>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'AdminView',
  components: {
    LogoutOutlined,
    UserOutlined
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const user = computed(() => store.getters['auth/user'])
    const loading = ref(false)

    onMounted(async () => {
      if (isAuthenticated.value && !authStatus.value) {
        await store.dispatch('auth/setUser').catch((e) => {
          console.log(e)
          router.push({ name: 'login' })
        })
      } else if (!isAuthenticated.value || !authStatus.value) {
        await router.push({ name: 'login' })
      }
    })

    const isMenuItemActive = (matchArray) => {
      route.matched.find((r) => {
        return matchArray.includes(r.name)
      })
      return !!route.matched.find((r) => matchArray.includes(r.name))
    }

    const loginAsDemoUser = async () => {
      loading.value = true
      await store.dispatch('auth/loginAsDemoUser')
      loading.value = false
    }

    const loginAsTemplateConstructorAdmin = async () => {
      loading.value = true
      await store.dispatch('auth/loginAsTemplateConstructorAdmin')
      loading.value = false
    }

    const logOut = async () => {
      loading.value = true
      await store.dispatch('auth/logOut')
      loading.value = false
    }

    return {
      route,
      user,
      loading,
      authStatus,
      isMenuItemActive,
      loginAsDemoUser,
      loginAsTemplateConstructorAdmin,
      logOut
    }
  }
})
</script>

<style lang="less">
@import "../styles/variables.less";

.ant-layout-header.header {
  padding: 0;
  .header-wrapper {
    background-color: @layout-header-background;
  }
  .actions {
    padding: 0 24px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    .anticon {
      font-size: 20px;
    }
    color: @text-color-secondary;
    &:hover {
      color: @text-color;
    }
  }
}
.ant-spin-nested-loading {
  height: inherit;
  .ant-spin-container {
    height: inherit;
    display: flex;
    flex-direction: column;
  }
}

</style>
