import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import AdminView from '@/views/AdminView'
import AccountsPage from '@/views/AccountsPage'
import AccountView from '@/views/AccountView'
import DevicesView from '@/views/DevicesView'
import PlaylistsView from '@/views/PlaylistsView'
import WorkspacesView from '@/views/WorkspacesView'
import GeneralPage from '@/views/GeneralPage'
import MembersView from '@/views/MembersView'
import SettingsPage from '@/views/SettingsPage.vue'
import AdminsPage from '@/views/AdminsPage.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/',
    name: 'admin',
    component: AdminView,
    redirect: { name: 'general' },
    children: [
      {
        path: '',
        name: 'general',
        component: GeneralPage
      },
      {
        path: 'users',
        name: 'users',
        component: AccountsPage
      },
      {
        path: 'admins',
        name: 'admins',
        component: AdminsPage
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsPage
      },
      {
        path: 'users/:userId',
        name: 'user',
        component: WorkspacesView,
        props: true,
        children: [{
          path: ':workspaceId',
          name: 'workspace',
          component: AccountView,
          props: true,
          children: [
            {
              path: ':groupId/devices',
              name: 'devices',
              component: DevicesView,
              props: true
            },
            {
              path: ':groupId/playlists',
              name: 'playlists',
              component: PlaylistsView,
              props: true
            }, {
              path: ':groupId/members',
              name: 'members',
              component: MembersView,
              props: true
            }
          ]
        }]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
