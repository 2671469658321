<template>
 <router-view></router-view>
</template>

<script>
import { defineComponent, toRef, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { GET_USER_WORKSPACES } from '@/graphql/queries'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  name: 'WorkspacesView',
  props: {
    userId: {
      type: String
    }
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const userId = toRef(props, 'userId')
    const { onResult: onWorkspaceResult, refetch } = useQuery(GET_USER_WORKSPACES, { userId: userId }, { fetchPolicy: 'no-cache' })

    onWorkspaceResult(({ data }) => {
      if (!route.matched.find((r) => r.name === 'workspace')) {
        return router.push({ name: 'workspace', params: { workspaceId: data.listWorkspaceMemberRolesByUserId[0]?.workspace.id }, replace: true })
      }
    })

    watch(route, () => {
      if (route.name === 'user') {
        refetch()
      }
    })

    return {

    }
  }
})
</script>

<style scoped>

</style>
