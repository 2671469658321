<template>
  <a-layout style="height: 100%" v-if="user">
    <a-modal v-model:visible="showChangeUserEmailModal" width="400px" title="Change User Email Address" @cancel="cancelUserEmailChange" @ok="onChangeEmailProceed" centered :closable="false">
      <a-form :model='changeEmailFormState' :rules="changeEmailRules" ref="changeEmailFormRef" @submit.prevent="onChangeEmailProceed">
        <a-form-item name="email">
          <a-input v-model:value="changeEmailFormState.email" placeholder="New Email" ref="changeEmailInputRef" name="email"/>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button key="submit" type="primary" :loading="emailChangeInProcess" @click="onChangeEmailProceed">Change Email Address</a-button>
      </template>
    </a-modal>
    <SubscriptionModal :workspace-id="workspaceId" :workspace="selectedWorkspace" v-model:visible="showSubscriptionInfo"/>
    <a-layout-content style="padding: 24px 24px 0 24px; display: flex; flex-direction: column">
      <a-row type="flex" style="flex: 1">
        <a-col flex="auto" style="text-align: left;">
          <a-row type="flex">
            <a-col flex="140px" ><b>Name</b></a-col>
            <a-col flex="220px" >{{ user.firstName }} {{ user.lastName }}</a-col>
            <a-col flex="140px" ><b>Phone Number</b></a-col>
            <a-col flex="220px" >{{ user.phone || 'Unset'}}</a-col>
            <a-col flex="140px" ><b>Company Name</b></a-col>
            <a-col flex="auto" >{{ user.companyName || 'Unset' }}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="140px" ><b>Email</b></a-col>
            <a-col flex="220px" >{{ user.email }}</a-col>
            <a-col flex="140px" ><b>Email Verified</b></a-col>
            <a-col flex="220px" >
              <a-badge status="processing" text="Verified" v-if="user.emailVerified" />
              <template v-else>
                <a-space>
                  <a-badge status="warning" text="Pending"/>
                  <a-popconfirm title="Verify email?" ok-text="Yes" cancel-text="No" @confirm="verifyEmail">
                    <a-button size="small" type="primary">Verify</a-button>
                  </a-popconfirm>
                </a-space>
              </template>
            </a-col>
            <a-col flex="140px" ><b>Company Size</b></a-col>
            <a-col flex="auto" >{{ user.companySize ? COMPANY_SIZE_LIST[user.companySize] : 'Unset'}}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="140px" ><b>Created At</b></a-col>
            <a-col flex="220px" >{{ formatDate(user.createdAt)}}</a-col>
            <a-col flex="140px" ><b>Updated At</b></a-col>
            <a-col flex="220px" >{{ formatDate(user.updatedAt)}}</a-col>
            <a-col flex="140px" ><b>Deleted At</b></a-col>
            <a-col flex="auto" >{{ formatDate(user.deletedAt) || 'Not deleted'}}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="140px" ><b>Password</b></a-col>
            <a-col flex="220px" >
              <a-badge status="processing" text="Set" v-if="user.isPasswordSet" />
              <a-badge status="error" text="Not set" v-else/>
            </a-col>
            <a-col flex="140px" ><b>Language</b></a-col>
            <a-col flex="220px" >{{ user.language ? LANGUAGES_LIST[user.language] : 'Unset'}}</a-col>
            <a-col flex="140px" ><b>JAMF Token</b></a-col>
            <a-col flex="auto" >
              {{ deviceInitializationToken }}
              <a-tooltip title="Copy config">
                <a-button size="small" type="link" @click="copyJamfConfig">
                  <template #icon>
                    <copy-outlined />
                  </template>
                </a-button>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="140px" ><b>Workspaces Limit</b></a-col>
            <a-col flex="220px" >
              <a-form @submit="changeWorkspaceLimit">
                <a-input-group compact>
                  <a-input-number v-model:value="ownedWorkspacesLimitState" :min="0" style="margin-bottom: 8px; width: 70px" size="small"></a-input-number>
                  <a-tooltip title="Save">
                    <a-button type="primary" size="small" html-type="submit" :disabled="updatingUser">
                      <template #icon>
                        <SaveOutlined />
                      </template>
                    </a-button>
                  </a-tooltip>
                </a-input-group>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="100px">
          <a-button type="primary" block style="margin-bottom: 8px;" @click="handleLoginAsUser">
            <template #icon><LoginOutlined /></template>
            Login as user
          </a-button>
          <a-button type="primary" block style="margin-bottom: 8px;" @click="startUserEmailChange">
            <template #icon><MailOutlined /></template>
            Change user email
          </a-button>
          <a-button type="primary" block style="margin-bottom: 8px;" @click="getResetPasswordLink" :loading="generatingLink">
            <template #icon><LinkOutlined /></template>
            Reset pwd link
          </a-button>
          <a-popconfirm
              title="Are you sure?"
              @confirm="handleRemoveUser"
          >
            <a-button danger block :loading="loading">
              <template #icon><DeleteOutlined /></template>
              Delete user
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>

      <a-layout style="height: 100%; padding-top: 24px; overflow: hidden;">
        <a-layout-sider theme="light" class="bordered" width="150" :collapsed="collapsed" :collapsedWidth="60">
          <a-menu theme="dark" mode="inline" :selectedKeys="['1']">
            <a-menu-item @click="toggleWorkspaceMenu" key="1">
              <template #icon>
                <MenuUnfoldOutlined
                    v-if="collapsed"
                    class="trigger"
                />
                <MenuFoldOutlined v-else class="trigger" />
              </template>
              <span v-if="!collapsed">Collapse</span>
              <span v-else>Expand</span>
            </a-menu-item>
          </a-menu>
          <a-menu theme="light" mode="inline" :selectedKeys="selectedWorkspaceKeys">
            <a-menu-item v-for="workspace in workspaces" :key="workspace.workspace.id" @click="selectWorkspace(workspace)" >
              <router-link :to="{name:'workspace', params:{workspaceId:workspace.workspace.id}}" v-if="user">{{ workspace.workspace?.name }}</router-link>
            </a-menu-item>
          </a-menu>
        </a-layout-sider>
        <GroupsSidebar @group-change="onGroupChange" :group="groupId" :workspaceId="selectedWorkspaceId" :key="reload" v-if="workspaceId"></GroupsSidebar>
        <a-layout-content style="padding-left: 24px; display: flex;">
          <a-row type="flex" style="flex-wrap: nowrap;  overflow: hidden; width: 100%;" :gutter="16">
            <a-col flex="auto" style="overflow: hidden;display: flex;flex-direction: column;">
              <a-menu mode="horizontal" v-if="groupId" :selectedKeys="selectedMenuKeys">
                <a-menu-item key="devices">
                  <router-link :to="{name:'devices'}">Devices</router-link>
                </a-menu-item>
                <a-menu-item key="playlists">
                  <router-link :to="{name:'playlists'}">Playlists</router-link>
                </a-menu-item>
                <a-menu-item key="members">
                  <router-link :to="{name:'members'}">Members</router-link>
                </a-menu-item>
              </a-menu>
              <router-view></router-view>
            </a-col>
            <a-col flex="0 0 400px" style="display: flex;flex-direction: column;">
              <div style="text-align: right">
                <a-button type="primary" @click="openSubscriptionInfo" style="margin-bottom: 16px;">Subscription Info</a-button>
              </div>
              <a-descriptions layout="horizontal" bordered style="text-align: left; overflow-x: auto" v-if="selectedWorkspace">
                <a-descriptions-item label="Frozen" :span="3">
                  <a-switch :disabled="updatingWorkspace" v-model:checked="workspaceSettings.frozen" checked-children="Yes" un-checked-children="No" @click="toggleFreezeWorkspace"/>
                </a-descriptions-item>
                <a-descriptions-item label="Enterprize" :span="3">
                  <a-switch :disabled="updatingWorkspace" v-model:checked="workspaceSettings.enterprize" checked-children="Yes" un-checked-children="No" @click="toggleEnterprize"/>
                </a-descriptions-item>
                <a-descriptions-item label="Devices" :span="3">{{selectedWorkspace.workspace?.billingInfo?.billingDevicesQuantity}}</a-descriptions-item>
                <a-descriptions-item label="Free devices" :span="3">
                    <a-form @submit="changeFreeDevicesNumber">
                      <a-input-group compact>
                        <a-input-number :disabled="updatingWorkspace" v-model:value="workspaceSettings.freeDevicesQuantity" :min="0" style="margin-bottom: 8px; width: 70px" size="small"></a-input-number>
                        <a-tooltip title="Save">
                          <a-button type="primary" size="small" html-type="submit" :disabled="updatingWorkspace">
                            <template #icon>
                              <SaveOutlined />
                            </template>
                          </a-button>
                        </a-tooltip>
                      </a-input-group>
                    </a-form>
                </a-descriptions-item>
                <a-descriptions-item label="Growth qty limit" :span="3">
                  <a-form @submit="changeStripeGrowthDevicesQuantityLimitNumber">
                    <a-input-group compact>
                      <a-input-number :disabled="updatingWorkspace" v-model:value="workspaceSettings.stripeGrowthDevicesQuantityLimit" :min="0" style="margin-bottom: 8px; width: 70px" size="small"></a-input-number>
                      <a-tooltip title="Save">
                        <a-button type="primary" size="small" html-type="submit" :disabled="updatingWorkspace">
                          <template #icon>
                            <SaveOutlined />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </a-input-group>
                  </a-form>
                </a-descriptions-item>
                <a-descriptions-item label="Custom Price Id" :span="3">
                  <a-form>
                    <a-input-group compact>
                      <a-input :disabled="updatingSubscription" v-model:value="workspaceSettings.stripeCustomPriceId" :min="0" style="margin-bottom: 8px; width: 70px" size="small"></a-input>
                      <a-tooltip title="Save">
                        <a-popconfirm title="Now or on renewal?" ok-text="On renewal" cancel-text="Now" @cancel="changeStripeCustomPriceId(true)" @confirm="changeStripeCustomPriceId()">
                          <a-button type="primary" size="small" html-type="submit" :disabled="updatingSubscription">
                            <template #icon>
                              <SaveOutlined />
                            </template>
                          </a-button>
                        </a-popconfirm>
                      </a-tooltip>
                    </a-input-group>
                  </a-form>
                </a-descriptions-item>
                <a-descriptions-item :label="`Size Limit (${formatFileSize(workspaceSettings.settings.demoStorageSizeLimit)})`" :span="3" v-if="workspaceSettings.settings?.demoStorageSizeLimit >= 0">
                  <a-form @submit="changeDemoStorageSizeLimit">
                    <a-input-group compact>
                      <a-input-number :disabled="updatingWorkspace" v-model:value="workspaceSettings.settings.demoStorageSizeLimit" :min="0" style="margin-bottom: 8px; width: 70px" size="small"></a-input-number>
                      <a-tooltip title="Save">
                        <a-button type="primary" size="small" html-type="submit" :disabled="updatingWorkspace">
                          <template #icon>
                            <SaveOutlined />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </a-input-group>
                  </a-form>
                </a-descriptions-item>
                <a-descriptions-item :label="feature.replace('Enabled', '')" v-for="(value, feature) in workspaceSettings.features" :key="feature" :span="3">
                  <a-switch :disabled="updatingWorkspace" :checked="value" checked-children="Yes" un-checked-children="No" @click="(value)=>toggleFeature(feature, value)"/>
                </a-descriptions-item>
              </a-descriptions>
            </a-col>
          </a-row>
        </a-layout-content>
      </a-layout>

    </a-layout-content>
  </a-layout>
</template>

<script>
import { defineComponent, toRef, ref, computed, nextTick, reactive, watchEffect } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { GET_USER_BY_ID, GET_USER_WORKSPACES } from '@/graphql/queries'
import { COMPANY_SIZE_LIST, LANGUAGES_LIST } from '@/constants'
import {
  LoginOutlined,
  DeleteOutlined,
  SaveOutlined,
  LinkOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MailOutlined,
  CopyOutlined
} from '@ant-design/icons-vue'
import { error, formatDate, formatFileSize, success } from '@/utils'
import GroupsSidebar from '@/components/GroupsSidebar'
import { useRouter, useRoute } from 'vue-router'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import {
  APPLY_RIGHT_TO_BE_FORGOTTEN,
  CANCEL_SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION,
  CHANGE_USER_EMAIL,
  GENERATE_USER_PASSWORD_RESET_LINK,
  LOGIN_AS_USER,
  SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION, UPDATE_USER_BY_ID,
  UPDATE_WORKSPACE_BY_ID,
  UPDATE_WORKSPACE_SUBSCRIPTION, VERIFY_USER_EMAIL
} from '@/graphql/mutations'
import SubscriptionModal from '@/components/SubscriptionModal'

export default defineComponent({
  name: 'AccountView',
  props: {
    userId: {
      type: String
    },
    workspaceId: {
      type: String
    },
    groupId: {
      type: String
    }
  },
  components: {
    SubscriptionModal,
    GroupsSidebar,
    LoginOutlined,
    DeleteOutlined,
    SaveOutlined,
    CopyOutlined,
    LinkOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    MailOutlined
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const selectedMenuKeys = computed(() => {
      return [route.name]
    })
    const userId = toRef(props, 'userId')
    const workspaceId = toRef(props, 'workspaceId')
    const reload = ref(0)
    const selectedWorkspace = ref(null)
    const collapsed = ref(!!localStorage.getItem('workspace-menu-collapsed'))
    const selectedGroupId = ref(null)
    const showSubscriptionInfo = ref(false)
    const showChangeUserEmailModal = ref(false)
    const ownedWorkspacesLimitState = ref(0)
    const workspaceSettings = reactive({
      enterprize: false,
      features: null,
      freeDevicesQuantity: 0,
      settings: null
    })
    const emailChangeInProcess = ref(false)
    const changeEmailInputRef = ref()
    const changeEmailFormRef = ref()
    const changeEmailFormState = reactive({
      email: ''
    })

    const { mutate: applyRightToBeForgotten, loading } = useMutation(APPLY_RIGHT_TO_BE_FORGOTTEN)
    const { mutate: verifyUserEmail } = useMutation(VERIFY_USER_EMAIL)
    const { mutate: updateWorkspace, updatingWorkspace } = useMutation(UPDATE_WORKSPACE_BY_ID)
    const { mutate: updateWorkspaceSubscription, updatingWorkspaceSubscription } = useMutation(UPDATE_WORKSPACE_SUBSCRIPTION)
    const { mutate: scheduleUpdateWorkspaceSubscription, updatingWorkspaceSubscriptionSchedule } = useMutation(SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION)
    const { mutate: cancelPendingUpdateWorkspaceSubscription, cancelingPendingWorkspaceSubscriptionSchedule } = useMutation(CANCEL_SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION)
    const { mutate: generateResetLink, generatingLink } = useMutation(GENERATE_USER_PASSWORD_RESET_LINK)
    const { mutate: loginAsUser } = useMutation(LOGIN_AS_USER)
    const { mutate: changeUserEmail } = useMutation(CHANGE_USER_EMAIL)
    const { result: userResult, refetch: refetchUser } = useQuery(GET_USER_BY_ID, { id: userId })
    const { mutate: updateUser, loading: updatingUser } = useMutation(UPDATE_USER_BY_ID)
    const { result: userWorkspacesResult, onResult: onWorkspaceResult } = useQuery(GET_USER_WORKSPACES, { userId: userId }, { fetchPolicy: 'no-cache' })

    const changeEmailRules = {
      email: [{
        required: true,
        message: 'New email is required',
        trigger: 'blur'
      },
      {
        type: 'email',
        message: 'Enter valid email address',
        trigger: 'blur'
      }]
    }

    const user = useResult(userResult, null)
    const ownedWorkspacesLimit = computed(() => user.value?.features?.ownedWorkspacesLimit)
    const workspaces = useResult(userWorkspacesResult, [])
    const activeTab = ref('accountInfo')

    onWorkspaceResult(({ data }) => {
      selectedWorkspace.value = data.listWorkspaceMemberRolesByUserId.find((w) => w.workspace?.id === workspaceId.value) || data.listWorkspaceMemberRolesByUserId[0]
      setWorkspaceData()
      if (!workspaceId.value) {
        return router.push({ name: 'workspace', params: { workspaceId: data.listWorkspaceMemberRolesByUserId[0]?.workspace.id } })
      }
    })

    const setWorkspaceData = () => {
      workspaceSettings.enterprize = selectedWorkspace.value.workspace?.billingInfo?.enterprize
      workspaceSettings.frozen = selectedWorkspace.value.workspace?.frozen
      workspaceSettings.features = selectedWorkspace.value.workspace?.features
      workspaceSettings.settings = selectedWorkspace.value.workspace?.settings
      workspaceSettings.features && delete workspaceSettings.features.__typename
      workspaceSettings.freeDevicesQuantity = selectedWorkspace.value.workspace?.billingInfo?.freeDevicesQuantity
      workspaceSettings.stripeGrowthDevicesQuantityLimit = selectedWorkspace.value.workspace?.billingInfo?.stripeGrowthDevicesQuantityLimit
      workspaceSettings.stripeCustomPriceId = selectedWorkspace.value.workspace?.billingInfo?.stripeCustomPriceId
    }

    const selectedWorkspaceId = computed(() => {
      return selectedWorkspace.value?.workspace?.id || null
    })

    const deviceInitializationToken = computed(() => {
      return selectedWorkspace.value?.workspace?.deviceInitializationToken
    })

    const selectedWorkspaceKeys = computed(() => {
      return [selectedWorkspaceId.value]
    })

    const updatingSubscription = computed(() => {
      return updatingWorkspaceSubscription || updatingWorkspaceSubscriptionSchedule || cancelingPendingWorkspaceSubscriptionSchedule
    })

    const selectWorkspace = (workspace) => {
      selectedWorkspace.value = workspace
      setWorkspaceData()
      nextTick(() => {
        reload.value++
      })
    }

    const handleRemoveUser = () => {
      applyRightToBeForgotten({ userId: userId.value }).then(() => {
        success('Done')
        router.push({ name: 'users', replace: true })
      }).catch((e) => {
        error(e.message)
      })
    }

    const toggleEnterprize = () => {
      const enterprizeTo = !selectedWorkspace.value.workspace?.billingInfo?.enterprize
      updateWorkspace({
        id: workspaceId.value,
        input: {
          billingInfo: {
            enterprize: enterprizeTo
          }
        }
      }).then(() => {
        success()
        selectedWorkspace.value.workspace.billingInfo.enterprize = enterprizeTo
      }).catch(e => {
        workspaceSettings.enterprize = selectedWorkspace.value.workspace.billingInfo.enterprize
        error(e.message)
      })
    }

    const toggleFreezeWorkspace = () => {
      const frozen = !selectedWorkspace.value.workspace?.frozen
      updateWorkspace({
        id: workspaceId.value,
        input: {
          frozen
        }
      }).then(() => {
        success()
        selectedWorkspace.value.workspace.frozen = frozen
      }).catch(e => {
        workspaceSettings.frozen = selectedWorkspace.value.workspace.frozen
        error(e.message)
      })
    }

    const toggleFeature = (feature, value) => {
      updateWorkspace({
        id: workspaceId.value,
        input: {
          features: {
            [feature]: value
          }
        }
      }).then(() => {
        success()
        selectedWorkspace.value.workspace.features[feature] = value
      }).catch(e => {
        error(e.message)
      })
    }

    const changeDemoStorageSizeLimit = () => {
      updateWorkspace({
        id: workspaceId.value,
        input: {
          settings: {
            demoStorageSizeLimit: workspaceSettings.settings?.demoStorageSizeLimit
          }
        }
      }).then(() => {
        success()
        selectedWorkspace.value.workspace.settings.demoStorageSizeLimit = workspaceSettings.settings.demoStorageSizeLimit
      }).catch(e => {
        workspaceSettings.settings.demoStorageSizeLimit = selectedWorkspace.value.workspace.settings.demoStorageSizeLimit
        error(e.message)
      })
    }

    const changeWorkspaceLimit = () => {
      updateUser({ id: user.value?.id, input: { features: { ownedWorkspacesLimit: ownedWorkspacesLimitState.value } } }).then(() => {
        refetchUser().then(() => {
          success()
        })
      })
    }

    const changeFreeDevicesNumber = () => {
      updateWorkspace({
        id: workspaceId.value,
        input: {
          billingInfo: {
            freeDevicesQuantity: workspaceSettings.freeDevicesQuantity
          }
        }
      }).then(() => {
        success()
        selectedWorkspace.value.workspace.billingInfo.freeDevicesQuantity = workspaceSettings.freeDevicesQuantity
      }).catch(e => {
        workspaceSettings.freeDevicesQuantity = selectedWorkspace.value.workspace.billingInfo.freeDevicesQuantity
        error(e.message)
      })
    }

    const changeStripeGrowthDevicesQuantityLimitNumber = () => {
      updateWorkspace({
        id: workspaceId.value,
        input: {
          billingInfo: {
            stripeGrowthDevicesQuantityLimit: workspaceSettings.stripeGrowthDevicesQuantityLimit
          }
        }
      }).then(() => {
        success()
        selectedWorkspace.value.workspace.billingInfo.stripeGrowthDevicesQuantityLimit = workspaceSettings.stripeGrowthDevicesQuantityLimit
      }).catch(e => {
        workspaceSettings.stripeGrowthDevicesQuantityLimit = selectedWorkspace.value.workspace.billingInfo.stripeGrowthDevicesQuantityLimit
        error(e.message)
      })
    }

    const changeStripeCustomPriceId = (now) => {
      const priceId = workspaceSettings.stripeCustomPriceId || null
      const currentDeviceQty = selectedWorkspace.value.workspace?.billingInfo?.billingDevicesQuantity
      let action
      let payload
      if (now && !priceId) return
      if (currentDeviceQty === 0) {
        action = updateWorkspace
        payload = {
          id: workspaceId.value,
          input: {
            billingInfo: {
              stripeCustomPriceId: priceId
            }
          }
        }
      } else if (now) {
        action = updateWorkspaceSubscription
        payload = {
          workspaceId: workspaceId.value,
          input: {
            billingItemsInfo: {
              billingDevicePriceId: priceId,
              billingDevicesQuantity: currentDeviceQty
            }
          }
        }
      } else {
        action = priceId ? scheduleUpdateWorkspaceSubscription : cancelPendingUpdateWorkspaceSubscription
        const input = {
          billingItemsInfo: {
            billingDevicePriceId: priceId,
            billingDevicesQuantity: currentDeviceQty
          }
        }
        payload = {
          workspaceId: workspaceId.value,
          ...(priceId ? { input } : null)
        }
      }
      action(payload).then(() => {
        success()
        selectedWorkspace.value.workspace.billingInfo.stripeCustomPriceId = priceId
      }).catch(e => {
        workspaceSettings.stripeCustomPriceId = selectedWorkspace.value.workspace.billingInfo.stripeCustomPriceId
        error(e.message)
      })
    }

    const toggleWorkspaceMenu = () => {
      collapsed.value = !collapsed.value
      localStorage.setItem('workspace-menu-collapsed', collapsed.value)
    }

    const handleLoginAsUser = () => {
      loginAsUser({ userId: userId.value, workspaceId: workspaceId.value }).then(({ data: { loginOnBehalfOfUser } }) => {
        const { accessToken } = loginOnBehalfOfUser
        window.open(`${process.env.VUE_APP_DASHBOARD_URL}/login-as/${accessToken}`, '_blank')
      })
    }

    const JamfConfigTemplate = `
      <dict>
      <key>secret</key>
      <string>%TOKEN%</string>
      <key>id</key>
      <string>$UDID</string>
      <key>name</key>
      <string>$DEVICENAME</string>
      <key>locationID</key>
      <string>$SITEID</string>
      <key>location</key>
      <string>$SITENAME</string>
      </dict>
    `
    const copyJamfConfig = () => {
      toClipboard(JamfConfigTemplate.replace('%TOKEN%', deviceInitializationToken.value)).then(() => {
        success('Copied to clipboard')
      })
    }

    const getResetPasswordLink = () => {
      generateResetLink({ userId: userId.value }).then(({ data: { generateUserPasswordResetLink: { url } } }) => {
        toClipboard(url).then(() => {
          success('Copied to clipboard')
        })
      }).catch((e) => {
        error(e.message)
      })
    }
    const onGroupChange = (groupId) => {
      const destinationParams = { params: { groupId }, replace: true }
      if (route.name === 'workspace') {
        destinationParams.name = 'devices'
      }
      groupId && router.push(destinationParams)
    }

    const openSubscriptionInfo = () => {
      showSubscriptionInfo.value = true
    }

    const startUserEmailChange = () => {
      showChangeUserEmailModal.value = true
      nextTick(() => {
        changeEmailInputRef.value && changeEmailInputRef.value.focus()
      })
    }

    const verifyEmail = () => {
      verifyUserEmail({ userId: userId.value }).then(() => {
        success('Email verified')
        refetchUser()
      })
    }

    const cancelUserEmailChange = () => {
      changeEmailFormState.email = ''
      emailChangeInProcess.value = false
      changeEmailInputRef.value && changeEmailInputRef.value.blur()
      changeEmailFormRef.value && changeEmailFormRef.value.resetFields()
      showChangeUserEmailModal.value = false
    }

    const onChangeEmailProceed = async () => {
      if (emailChangeInProcess.value) return
      try {
        await changeEmailFormRef.value.validate()
      } catch (e) {
        if (e.errorFields.length) {
          return
        }
      }
      emailChangeInProcess.value = true
      changeUserEmail({ userId: userId.value, input: { email: changeEmailFormState.email } })
        .then(() => {
          cancelUserEmailChange()
          success('Email changed')
          refetchUser()
        })
        .catch(e => {
          error(e.message)
        }).then(() => {
          emailChangeInProcess.value = false
        })
    }

    watchEffect(() => {
      ownedWorkspacesLimitState.value = ownedWorkspacesLimit.value
    })

    return {
      user,
      reload,
      loading,
      route,
      updatingSubscription,
      updatingWorkspace,
      updatingWorkspaceSubscriptionSchedule,
      cancelingPendingWorkspaceSubscriptionSchedule,
      workspaces,
      workspaceSettings,
      showSubscriptionInfo,
      selectedWorkspaceKeys,
      deviceInitializationToken,
      selectedMenuKeys,
      selectedWorkspace,
      selectedWorkspaceId,
      showChangeUserEmailModal,
      changeEmailRules,
      selectedGroupId,
      activeTab,
      generatingLink,
      collapsed,
      emailChangeInProcess,
      changeEmailInputRef,
      changeEmailFormRef,
      changeEmailFormState,
      ownedWorkspacesLimitState,
      updatingUser,
      COMPANY_SIZE_LIST,
      LANGUAGES_LIST,
      changeDemoStorageSizeLimit,
      formatDate,
      copyJamfConfig,
      startUserEmailChange,
      cancelUserEmailChange,
      onChangeEmailProceed,
      getResetPasswordLink,
      toggleEnterprize,
      toggleFeature,
      handleRemoveUser,
      handleLoginAsUser,
      changeFreeDevicesNumber,
      changeStripeGrowthDevicesQuantityLimitNumber,
      changeStripeCustomPriceId,
      changeWorkspaceLimit,
      toggleWorkspaceMenu,
      toggleFreezeWorkspace,
      openSubscriptionInfo,
      onGroupChange,
      selectWorkspace,
      formatFileSize,
      verifyEmail
    }
  }
})
</script>

<style scoped lang="less">

</style>
