export const DAY_IN_MILLISECONDS = 60 * 60 * 24 * 1000

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const LANGUAGES_LIST = {
  RUSSIAN: 'Russian',
  ENGLISH_US: 'English',
  GERMAN: 'German',
  SPANISH: 'Spanish',
  FRENCH: 'French',
  ITALIAN: 'Italian',
  CHINESE: 'Chinese',
  PORTUGUESE: 'Portuguese',
  JAPANESE: 'Japanese'
}

export const COMPANY_SIZE_LIST = {
  JUST_ME: 'Just me',
  FROM_2_TO_5: '2-5',
  FROM_6_TO_20: '6-20',
  FROM_21_TO_50: '21-50',
  FROM_51_TO_100: '51-100',
  FROM_101_TO_500: '101-500',
  MORE_THAN_500: '500+'
}

export const DEFAULT_PAGINATION = {
  offset: 0,
  limit: 10
}
