import {
  LOGIN_AS_USER,
  LOGIN_USER,
  LOGOUT_ADMIN, PASS_TWO_FACTOR_CHECK
} from '@/graphql/mutations'
import { GET_DEMO_USER, GET_PRE_CREATED_WIDGETS_USER, GET_USER_WORKSPACES, LOGGED_IN_USER } from '@/graphql/queries'
import ApolloClient from '@/apollo'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('auth-token') || null,
    initialized: false,
    user: {},
    authStatus: false
  },
  actions: {
    async login ({ commit, dispatch }, authDetails) {
      try {
        const { data } = await ApolloClient.mutate({ mutation: LOGIN_USER, variables: { ...authDetails } })
        const token = data.loginAdmin.accessToken
        commit('SET_TOKEN', token)
        localStorage.setItem('auth-token', token)
        await dispatch('setUser')
      } catch (e) {
        if (e.graphQLErrors?.[0]?.extensions?.requires2FA) {
          return e.graphQLErrors?.[0]?.extensions
        }
        throw new Error(e)
      }
    },
    async loginWith2Fa ({ commit, dispatch, getters }, { preAuthToken, twoFactorCode }) {
      try {
        const { data: { passTwoFactorCheck } } = await ApolloClient.mutate({ mutation: PASS_TWO_FACTOR_CHECK, variables: { preAuthToken, twoFactorCode } })
        const token = passTwoFactorCheck.accessToken
        commit('SET_TOKEN', token)
        localStorage.setItem('auth-token', token)
        await dispatch('setUser')
      } catch (e) {
        throw new Error(e)
      }
    },
    async setUser ({ commit }) {
      try {
        const { data } = await ApolloClient.query({ query: LOGGED_IN_USER })
        commit('LOGIN_USER', data.getMyProfile)
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async loginAsDemoUser () {
      try {
        const { data: { getPreCreatedWidgetsUser: { id } } } = await ApolloClient.query({ query: GET_DEMO_USER })
        const { data: { listWorkspaceMemberRolesByUserId } } = await ApolloClient.query({ query: GET_USER_WORKSPACES, variables: { userId: id } })
        const workspaceId = listWorkspaceMemberRolesByUserId[0]?.workspace.id
        const { data: { loginOnBehalfOfUser: { accessToken } } } = await ApolloClient.mutate({ mutation: LOGIN_AS_USER, variables: { userId: id, workspaceId } })
        window.open(`${process.env.VUE_APP_DASHBOARD_URL}/login-as/${accessToken}`, '_blank')
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async loginAsTemplateConstructorAdmin () {
      try {
        const { data: { getPreCreatedWidgetsUser: { id } } } = await ApolloClient.query({ query: GET_PRE_CREATED_WIDGETS_USER })
        const { data: { listWorkspaceMemberRolesByUserId } } = await ApolloClient.query({ query: GET_USER_WORKSPACES, variables: { userId: id } })
        const workspaceId = listWorkspaceMemberRolesByUserId[0]?.workspace.id
        const { data: { loginOnBehalfOfUser: { accessToken } } } = await ApolloClient.mutate({ mutation: LOGIN_AS_USER, variables: { userId: id, workspaceId } })
        window.open(`${process.env.VUE_APP_DASHBOARD_URL}/login-as/${accessToken}`, '_blank')
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async logOut ({ dispatch }) {
      try {
        await ApolloClient.mutate({ mutation: LOGOUT_ADMIN })
        await dispatch('clearUser')
      } catch (e) {
        console.log(e)
      }
    },
    async clearUser ({ commit }) {
      await ApolloClient.cache.reset()
      await router.push({ name: 'login' })
      commit('LOGOUT_ADMIN')
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authStatus,
    user: state => state.user,
    is2faSet: state => state.user?.is2faSet,
    initialized: state => state.initialized
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    LOGIN_USER (state, user) {
      state.authStatus = true
      state.user = { ...user }
    },
    UPDATE_USER (state, user) {
      state.user = { ...state.user, ...user }
    },
    LOGOUT_ADMIN (state) {
      state.authStatus = false
      state.token = localStorage.removeItem('auth-token')
    }
  }
}
