<template>
  <a-layout v-if="user">
    <a-layout-content style="padding: 24px; width: 1200px; margin: auto;">
      <a-row>
        <a-col :span="12" style="padding: 0 16px 0 0">
          <a-typography-title :level="2" type="secondary" align="center">General stats</a-typography-title>
          <a-row style="margin-top: 24px;">
            <a-col :span="8">
              <a-statistic title="Ongoing This Week" :value="stats?.stripe?.currentWeekUpcomingInvoicesAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Ongoing This Month" :value="stats?.stripe?.currentMonthUpcomingInvoicesAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Ongoing This Year" :value="stats?.stripe?.currentYearUpcomingInvoicesAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
          </a-row>
          <a-row style="margin-top: 24px;">
            <a-col :span="8">
              <a-statistic title="Revenue This Week" :value="stats?.stripe?.currentWeekPaidInvoicesAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Revenue This Month" :value="stats?.stripe?.currentMonthPaidInvoicesAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Revenue This Year" :value="stats?.stripe?.currentYearPaidInvoicesAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
          </a-row>
          <a-row style="margin-top: 24px;">
            <a-col :span="8">
              <a-statistic title="New Payments This Week" :value="stats?.stripe?.currentWeekNewSubscriptionsAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="New Payments This Month" :value="stats?.stripe?.currentMonthNewSubscriptionsAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="New Payments This Year" :value="stats?.stripe?.currentYearNewSubscriptionsAmount" prefix="$" :formatter="formatCurrency"/>
            </a-col>
          </a-row>
          <a-divider/>
          <a-row>
            <a-col :span="8">
              <a-statistic title="All Users" :value="stats?.users?.totalCount"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Active Users" :value="stats?.workspaces?.paidCount"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Inactive Users" :value="stats?.workspaces?.inactiveCount"/>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Workspace Owners" :value="stats?.workspaces?.totalCount"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Past Due Users" :value="stats?.workspaces?.pastDueCount"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Demo Users" :value="stats?.workspaces?.demoCount"/>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Enterprize Users Owners" :value="stats?.enterprizeWorkspaces?.totalCount"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Enterprize Users Past Due" :value="stats?.enterprizeWorkspaces?.pastDueCount"/>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Growth Users Owners" :value="stats?.growthWorkspaces?.totalCount"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Growth Users Past Due" :value="stats?.growthWorkspaces?.pastDueCount"/>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Migrated Users" :value="stats?.users?.usersCreatedByV1MigrationCount"/>
            </a-col>
          </a-row>
          <a-divider/>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Devices Total" :value="stats?.devices?.totalCount"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Devices Online" :value="stats?.devices?.onlineDevicesCount"/>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12" style="padding: 0 0 0 16px; text-align: center">
          <a-typography-title :level="2" type="secondary" align="center">Third Party Services</a-typography-title>
          <a-row style="margin-top: 24px;">
            <a-col :span="8">
              <a-statistic title="Dropbox Auth" :value="getStatusText(thirdPartyStatuses?.dropboxOauthApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.dropboxOauthApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Google Auth" :value="getStatusText(thirdPartyStatuses?.googleOauthApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.googleOauthApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Instagram Auth" :value="getStatusText(thirdPartyStatuses?.instagramOauthApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.instagramOauthApiOk)"/>
            </a-col>
          </a-row>
          <a-divider/>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Microsoft Auth" :value="getStatusText(thirdPartyStatuses?.microsoftOauthApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.microsoftOauthApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Twitter Auth" :value="getStatusText(thirdPartyStatuses?.twitterOauthApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.twitterOauthApiOk)"/>
            </a-col>

          </a-row>
          <a-divider/>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Weather Api" :value="getStatusText(thirdPartyStatuses?.weatherApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.weatherApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Yelp Api" :value="getStatusText(thirdPartyStatuses?.yelpApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.yelpApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Covid Api" :value="getStatusText(thirdPartyStatuses?.covidStatsApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.covidStatsApiOk)"/>
            </a-col>
          </a-row>
          <a-divider/>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Google Reviews API" :value="getStatusText(thirdPartyStatuses?.googleReviewsApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.googleReviewsApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Fun Facts API" :value="getStatusText(thirdPartyStatuses?.rapidApiFunFactsApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.rapidApiFunFactsApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Horoscopes Api" :value="getStatusText(thirdPartyStatuses?.rapidApiHoroscopesApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.rapidApiHoroscopesApiOk)"/>
            </a-col>
          </a-row>
          <a-divider/>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Holidays API" :value="getStatusText(thirdPartyStatuses?.rapidApiPublicHolidaysApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.rapidApiPublicHolidaysApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Tasty API" :value="getStatusText(thirdPartyStatuses?.rapidApiTastyApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.rapidApiTastyApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Ted Talks Api" :value="getStatusText(thirdPartyStatuses?.rapidApiTedTalksApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.rapidApiTedTalksApiOk)"/>
            </a-col>
          </a-row>
          <a-divider/>
          <a-row>
            <a-col :span="8">
              <a-statistic title="Ticker API" :value="getStatusText(thirdPartyStatuses?.tickerApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.tickerApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Trip Advisor API" :value="getStatusText(thirdPartyStatuses?.tripAdvisorApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.tripAdvisorApiOk)"/>
            </a-col>
            <a-col :span="8">
              <a-statistic title="News API" :value="getStatusText(thirdPartyStatuses?.newsApiOk)" :value-style="getStatusStyle(thirdPartyStatuses?.newsApiOk)"/>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" style="padding: 0 16px 0 0; margin-top: 32px; text-align: center;">
          <a-typography-title :level="2" type="secondary" align="center">Tracking quantities</a-typography-title>
          <a-row style="margin-top: 24px;">
            <a-col :span="6" v-for="item in trackingQuantities" :key="item.name">
              <a-statistic :title="item.name?.replace(/-/gi, ' ').replace('count', '')" :value="`${item.value} / ${item.maxValue || '∞'}`" :value-style="getStatusStyle(getQuantityStatus(item))"/>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useQuery, useResult } from '@vue/apollo-composable'
import { GET_STATS, GET_THIRD_PARTY_STATUSES, GET_TRACKING_QUANTITIES } from '@/graphql/queries'

export default defineComponent({
  name: 'GeneralPage',
  setup () {
    const store = useStore()
    const { result: statsResult } = useQuery(GET_STATS)
    const { result: thirdPartyStatusesResult } = useQuery(GET_THIRD_PARTY_STATUSES)
    const { result: trackingQuantitiesResult } = useQuery(GET_TRACKING_QUANTITIES)
    const stats = useResult(statsResult)
    const thirdPartyStatuses = useResult(thirdPartyStatusesResult)
    const trackingQuantities = useResult(trackingQuantitiesResult)

    const user = computed(() => store.getters['auth/user'])

    const formatCurrency = ({ value }) => {
      return value ? value / 100 : '0.00'
    }

    const getStatusText = (status) => {
      return status ? 'OK' : 'NOT OK'
    }

    const getStatusStyle = (status) => {
      return {
        color: status ? '#3f8600' : '#cf1322'
      }
    }
    // TODO MAKE trackingQuantities a table

    const getQuantityStatus = ({ value, maxValue, currentPeriodStart, currentPeriodEnd }) => {
      if (maxValue === null) return true
      return value / maxValue < 0.1 || (new Date() - currentPeriodStart) / (currentPeriodEnd - currentPeriodStart) > value / maxValue
    }

    return {
      user,
      stats,
      thirdPartyStatuses,
      trackingQuantities,
      getStatusText,
      getStatusStyle,
      getQuantityStatus,
      formatCurrency
    }
  }
})
</script>

<style scoped>

</style>
