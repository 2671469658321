import { message } from 'ant-design-vue'
import moment from 'moment'

export const success = (content = 'Done!', duration = 2) => {
  message.success({
    content,
    duration
  })
}

export const error = (content = 'Error', duration = 2) => {
  message.error({
    content,
    duration
  })
}

export const warn = (content = 'Error', duration = 2) => {
  message.warn({
    content,
    duration
  })
}

export const formatDate = (value, format) => {
  if (value) {
    return moment(String(value)).format(format || 'MMM Do YYYY')
  }
}

export const formatUnixDate = (value) => {
  if (value) {
    return moment(value * 1000).format('MMM D,YYYY')
  }
}

export const stripTypename = (obj) => {
  return omitDeep(obj, '__typename')
}

const omitDeep = (obj, key) => {
  const keys = Object.keys(obj)
  const newObj = {}
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (val instanceof Date) newObj[i] = val
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key)
      else newObj[i] = val
    }
  })
  return newObj
}

const omitDeepArrayWalk = (arr, key) => {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    else if (typeof val === 'object') return omitDeep(val, key)
    return val
  })
}

export const formatFileSize = (size) => {
  if (!size) return ''
  if (parseInt(size) === 0) return 0 + 'B'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + '' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}
