<template>
  <a-modal :visible = visible :footer="null" width="1000px" @cancel = close :closable="!loading" :maskClosable="!loading">
    <template #title>
      Subscription info
    </template>
    <a-spin :spinning="loading">
      <a-row>
        <a-col :span="3"><b>Created</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.created)}}</a-col>
        <a-col :span="3" :offset="1"><b>Period start</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.current_period_start)}}</a-col>
        <a-col :span="3" :offset="1"><b>Period end</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.current_period_end)}}</a-col>
      </a-row>
      <a-row>
        <a-col :span="3"><b>Device qty</b></a-col>
        <a-col :span="4">
          <a-input-number v-if="subscriptionState?.billingItemsInfo" v-model:value="subscriptionState.billingItemsInfo.billingDevicesQuantity" :min="1" :max="10000" size="small" @pressEnter="handleWorkspaceSubscriptionUpdate" :disabled="loading"/>
        </a-col>
        <a-col :span="3" :offset="1"><b>Billing Cycle</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.billing_cycle_anchor)}}</a-col>
        <a-col :span="3" :offset="1"><b>Next Invoice</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.next_pending_invoice_item_invoice) || 'None'}}</a-col>
      </a-row>
      <a-row>
        <a-col :span="3"><b>Started At</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.start_date) || 'None'}}</a-col>
        <a-col :span="3" :offset="1"><b>Ended At</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.ended_at) || 'None'}}</a-col>
        <a-col :span="3" :offset="1"><b>Canceled At</b></a-col>
        <a-col :span="4">{{formatUnixDate(subscriptionDetails?.canceled_at) || 'None'}}</a-col>
      </a-row>
      <a-row v-if="subscriptionDetails?.discount">
        <a-col :span="3"><b>Discount Name</b></a-col>
        <a-col :span="4">{{subscriptionDetails?.discount?.coupon?.name}}</a-col>
        <a-col :span="3" :offset="1"><b>Discount End</b></a-col>
        <a-col :span="4">{{subscriptionDetails?.discount?.end || 'Forever'}}</a-col>
        <a-col :span="3" :offset="1"><b>Amount</b></a-col>
        <a-col :span="4">
          <span v-if="subscriptionDetails?.discount?.coupon?.currency">
            {{subscriptionDetails?.discount?.coupon?.currency}}
            {{subscriptionDetails?.discount?.coupon?.amount_off}}
          </span>
          <span v-else>
              {{subscriptionDetails?.discount?.coupon?.percent_off}}%
            </span>
          <a-tooltip>
            <template #title>
              <JsonViewer :value="subscriptionDetails?.discount" copyable preview-mode sort theme="jv-light"/>
            </template>
            <InfoCircleOutlined/>
          </a-tooltip>
        </a-col>
      </a-row>
      <a-row v-if="subscriptionState?.billingSettings">
        <a-col :span="3"><b>Col. Method</b></a-col>
        <a-col :span="5">
          <a-select v-if="subscriptionState.billingSettings" size="small" multiple v-model:value="subscriptionState.billingSettings.collectionMethod" :options="COLLECTION_METHOD_OPTIONS"/>
        </a-col>
        <a-col :span="3"><b>Days to dew</b></a-col>
        <a-col :span="4">
          <a-input-number v-if="subscriptionState?.billingSettings" v-model:value="subscriptionState.billingSettings.daysUntilDue" :min="1" :max="10000" size="small" @pressEnter="handleWorkspaceSubscriptionUpdate" :disabled="loading || subscriptionState?.billingSettings?.collectionMethod === 'charge_automatically'"/>
        </a-col>
        <a-col :span="3" :offset="1"><b>Paym. methods</b></a-col>
        <a-col :span="4">
          <a-select v-if="subscriptionState.billingSettings" size="small" multiple v-model:value="subscriptionState.billingSettings.paymentMethodTypes" :options="PAYMENT_METHOD_OPTIONS"/>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="3"><b>Balance</b></a-col>
        <a-col :span="5">${{customerDetails?.balance}}</a-col>
        <a-col :span="3"><b>Status</b></a-col>
        <a-col :span="5">{{subscriptionDetails?.status}}</a-col>
        <a-col :span="3"><b>Price Id</b></a-col>
        <a-col :span="5">{{subscriptionState.billingItemsInfo?.billingDevicePriceId}}</a-col>
      </a-row>
      <a-row>
        <a-col :span="3"><b>Tier</b></a-col>
        <a-col :span="5">{{workspace?.workspace?.billingInfo?.tier}}</a-col>
      </a-row>
      <a-button type="primary" @click="handleWorkspaceSubscriptionUpdate" style="width: 100px;margin-top: 8px; align-self: flex-end" :loading="loading">Update</a-button>
      <a-divider/>
        <a-row>
          <a-col :span="2"><b>Add Trial</b></a-col>
          <a-col :span="2" style="text-align: end; margin-right: 8px;"><b>Days:</b></a-col>
          <a-col :span="3">
            <a-input-number v-model:value="trialState.trialDays" :min="0" :max="50" size="small" @pressEnter="handleAddTrial" :disabled="loading"/>
          </a-col>
          <a-col :span="2" style="text-align: end; margin-right: 8px;"><b>Devices:</b></a-col>
          <a-col :span="3">
            <a-input-number v-model:value="trialState.trialDevicesQuantity" :min="1" :max="50" size="small" @pressEnter="handleAddTrial" :disabled="loading"/>
          </a-col>
          <a-col :span="5">
            <a-button type="primary" size="small" @click="handleAddTrial" style="align-self: flex-end; width: 100px;" :loading="loading">Add Trial</a-button>
          </a-col>
        </a-row>
      <a-divider/>
    </a-spin>
    <a-space>
      <a :href="customerDetails?.stripeDashboardUrl" target="_blank">Customer</a>
      <a-divider type="vertical"/>
      <a :href="subscriptionDetails?.stripeDashboardUrl" target="_blank">Subscription</a>
      <a-divider type="vertical"/>
      <a-button size="small" danger @click="onCancelWorkspaceSubscription" :loading="cancelingWorkspaceSubscription" :disabled="!subscriptionDetails || subscriptionDetails?.status === 'canceled'">Cancel Subscription</a-button>
    </a-space>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, toRef, watch } from 'vue'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { error, formatUnixDate, stripTypename, success } from '@/utils'
import { GET_WORKSPACE_CUSTOMER, GET_WORKSPACE_SUBSCRIPTION, LIST_STRIPE_PRICES } from '@/graphql/queries'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { CANCEL_WORKSPACE_SUBSCRIPTION, UPDATE_WORKSPACE_SUBSCRIPTION } from '@/graphql/mutations'
import { cloneDeep } from '@apollo/client/utilities'

const PAYMENT_METHOD_OPTIONS = [
  {
    label: 'Not Set',
    value: null
  },
  {
    label: 'Card',
    value: 'card'
  },
  {
    label: 'ACH Direct',
    value: 'ach_debit'
  },
  {
    label: 'Canadian Debit',
    value: 'acss_debit'
  },
  {
    label: 'Bank or Wire',
    value: 'us_bank_account'
  },
  {
    label: 'ACH credit',
    value: 'ach_credit_transfer'
  },
  {
    label: 'Check',
    value: 'paper_check'
  }
  // 'wechat_pay'
  // 'link'
]

const COLLECTION_METHOD_OPTIONS = [
  {
    label: 'Automatically',
    value: 'charge_automatically'
  },
  {
    label: 'Invoice',
    value: 'send_invoice'
  }
]

const DEFAULT_TRIAL_DAYS = 14
const DEFAULT_TRIAL_DEVICES_QUANTITY = 10

export default defineComponent({
  name: 'SubscriptionModal',
  props: {
    workspaceId: String,
    workspace: Object,
    visible: Boolean
  },
  components: {
    InfoCircleOutlined
  },
  setup (props, { emit }) {
    const workspaceId = toRef(props, 'workspaceId')
    const subscriptionDetails = ref(null)
    const { onResult: onSubscriptionResult, refetch: refetchSubscription } = useQuery(GET_WORKSPACE_SUBSCRIPTION, { workspaceId }, { fetchPolicy: 'network-only' })
    const { result: customerResult } = useQuery(GET_WORKSPACE_CUSTOMER, { workspaceId }, { fetchPolicy: 'network-only' })
    const { result: pricesResult } = useQuery(LIST_STRIPE_PRICES, { }, { fetchPolicy: 'network-only' })
    const { mutate: updateWorkspaceSubscription, loading } = useMutation(UPDATE_WORKSPACE_SUBSCRIPTION)
    const { mutate: cancelWorkspaceSubscription, cancelingWorkspaceSubscription } = useMutation(CANCEL_WORKSPACE_SUBSCRIPTION)
    const customerDetails = useResult(customerResult)
    const pricesList = useResult(pricesResult, [], data => data.listStripePrices.data)
    const subscriptionState = reactive({
    })

    const trialState = reactive({
      trialDays: DEFAULT_TRIAL_DAYS,
      trialDevicesQuantity: DEFAULT_TRIAL_DEVICES_QUANTITY
    })

    onSubscriptionResult(({ data }) => {
      data?.getWorkspaceSubscription && mapSubscriptionToState(data?.getWorkspaceSubscription)
    })

    const mapSubscriptionToState = (subscriptionInfo) => {
      subscriptionDetails.value = subscriptionInfo
      setSubscriptionState()
    }

    const setSubscriptionState = () => {
      if (!subscriptionDetails.value) return
      const { billingItemsInfo, billingSettings } = cloneDeep(subscriptionDetails.value)
      subscriptionState.billingItemsInfo = billingItemsInfo ? stripTypename(billingItemsInfo) : null
      subscriptionState.billingSettings = billingSettings ? stripTypename(billingSettings) : null
    }

    const resetSubscriptionState = () => {
      setSubscriptionState()
    }

    const resetTrialState = () => {
      trialState.trialDays = DEFAULT_TRIAL_DAYS
      trialState.trialDevicesQuantity = DEFAULT_TRIAL_DEVICES_QUANTITY
    }

    const handleWorkspaceSubscriptionUpdate = () => {
      if (loading.value) return
      updateWorkspaceSubscription({
        workspaceId: workspaceId.value,
        input: {
          ...(subscriptionState.billingItemsInfo ? { billingItemsInfo: subscriptionState.billingItemsInfo } : null),
          ...(subscriptionState.billingSettings ? { billingSettings: subscriptionState.billingSettings } : null)
        }
      }).then(({ data }) => {
        data?.updateWorkspaceSubscription && mapSubscriptionToState(data?.updateWorkspaceSubscription)
      }).catch(e => {
        error(e.message)
      })
    }

    const handleAddTrial = () => {
      if (loading.value) return
      updateWorkspaceSubscription({
        workspaceId: workspaceId.value,
        input: {
          trialItemsInfo: {
            trialDays: trialState.trialDays,
            trialDevicesQuantity: trialState.trialDevicesQuantity
          }
        }
      }).then(({ data }) => {
        data?.updateWorkspaceSubscription && mapSubscriptionToState(data?.updateWorkspaceSubscription)
        success('Trial added')
      }).catch(e => {
        error(e.message)
      })
    }

    const onCancelWorkspaceSubscription = () => {
      cancelWorkspaceSubscription({ workspaceId: workspaceId.value }).then(() => {
        refetchSubscription()
      })
    }

    const close = () => {
      emit('update:visible', false)
      resetTrialState()
      resetSubscriptionState()
    }

    watch(() => subscriptionState.billingSettings && subscriptionState.billingSettings.collectionMethod, (collectionMethod) => {
      subscriptionState.billingSettings.daysUntilDue = collectionMethod === 'charge_automatically' ? null : (subscriptionState.billingSettings.daysUntilDue || 0)
    })

    return {
      loading,
      pricesList,
      customerDetails,
      trialState,
      subscriptionState,
      subscriptionDetails,
      PAYMENT_METHOD_OPTIONS,
      COLLECTION_METHOD_OPTIONS,
      cancelingWorkspaceSubscription,
      close,
      formatUnixDate,
      handleAddTrial,
      handleWorkspaceSubscriptionUpdate,
      onCancelWorkspaceSubscription
    }
  }
})
</script>

<style scoped>

</style>
