<template>
  <a-layout style="padding: 24px; text-align: left;">
    <a-layout-content>
      <a-input-search
          v-model:value="searchValue"
          placeholder="Input search text"
          style="width: 200px; margin-bottom: 24px"
          @change="onSearch"
      />
      <a-table
          :data-source="adminsList"
          @resizeColumn="handleResizeColumn"
          :columns="columns"
          :loading="loading"
          size="small"
          :pagination="paginationConfig"
          @change="onChange"
          class="table-padded">
        <template #bodyCell="{ column, text }">
          <template v-if="column.key === 'createdAt'">
            {{formatDate(text)}}
          </template>
          <template v-else-if="['is2faSet', 'isPasswordSet'].includes(column.key)">
            {{ text ? 'Yes' : 'No' }}
          </template>
          <template v-else>
            {{text}}
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent, ref, nextTick } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { cloneDeep, debounce } from 'lodash'
import { formatDate } from '@/utils'
import { LIST_ADMINS } from '@/graphql/queries'
import { DEFAULT_PAGINATION } from '@/constants'

const COLUMNS = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  width: 200,
  minWidth: 200,
  maxWidth: 400,
  resizable: true
},
{
  title: 'Email',
  dataIndex: 'username',
  key: 'username',
  width: 120,
  minWidth: 120,
  maxWidth: 120
},
{
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 200,
  minWidth: 200,
  maxWidth: 200
},
{
  title: '2FA Set',
  dataIndex: 'is2faSet',
  key: 'is2faSet',
  width: 120,
  minWidth: 120,
  maxWidth: 120
},
{
  title: 'Password Set',
  dataIndex: 'isPasswordSet',
  key: 'isPasswordSet',
  width: 120,
  minWidth: 120,
  maxWidth: 120
}]

export default defineComponent({
  name: 'AdminsPage',
  setup () {
    const settings = ref({ enabled: false, fetchPolicy: 'network-only' })
    nextTick(() => {
      settings.value.enabled = true
    })
    const searchValue = ref('')
    const queryOptions = ref({ pagination: { ...DEFAULT_PAGINATION }, filters: { search: null } })
    const { onResult, loading } = useQuery(LIST_ADMINS, queryOptions, settings)
    const adminsList = ref([])
    const paginationResult = ref({})
    const paginationConfig = computed(() => {
      return {
        total: paginationResult.value?.total,
        pageSize: DEFAULT_PAGINATION.limit
      }
    })
    const columns = ref(cloneDeep(COLUMNS))

    onResult(({ data }) => {
      if (data?.listAdmins?.data) {
        adminsList.value = data.listAdmins.data
        paginationResult.value = data.listAdmins.pagination
      }
    })

    const handleResizeColumn = (w, col) => {
      col.width = w
    }

    const onChange = async (pagination) => {
      queryOptions.value.pagination.offset = (pagination.current - 1) * DEFAULT_PAGINATION.limit
    }

    const onSearch = debounce(() => {
      if (searchValue.value.length > 2) {
        queryOptions.value.filters.search = searchValue.value.toLowerCase()
      } else if (searchValue.value === '') {
        queryOptions.value.filters.search = null
      }
    }, 500)

    return {
      columns,
      loading,
      adminsList,
      paginationConfig,
      searchValue,
      onSearch,
      formatDate,
      onChange,
      handleResizeColumn
    }
  }
})
</script>

<style lang="less">
.ant-table-row {
  cursor: pointer;
}

</style>
