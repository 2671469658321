<template>
  <a-table
      :data-source="playlistVersionsList"
      :columns="versionsColumns"
      :rowKey="(record)=>record.version"
      :loading="playlistVersionsLoading"
      size="small"
      :pagination="playlistVersionsPaginationConfig"
      style="margin-top: 8px;"
      :scroll="{ x: 600 }"
      class="table-padded">
    <template #bodyCell="{ column, text, record }">
      <template v-if="['createdAt','publishedAt'].includes(column.key)">
        {{formatDate(text, 'MMM Do YY mm:ss')}}
      </template>
      <template v-else-if="column.key === 'actions'">
        <template v-if="!record.isPublished">
          <a-typography-link @click="publishVersion(record.version)">
            Publish
          </a-typography-link>
        </template>
      </template>

      <template v-else>
        {{text}}
      </template>
    </template>
  </a-table>
</template>

<script>
import { computed, defineComponent, ref, toRef } from 'vue'
import { DEFAULT_PAGINATION } from '@/constants'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { LIST_PLAYLIST_VERSIONS } from '@/graphql/queries'
import { cloneDeep } from 'lodash'
import { error, formatDate, success } from '@/utils'
import { PUBLISH_USER_PLAYLIST_VERSION } from '@/graphql/mutations'

const VERSIONS_COLUMNS = [{
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Published At',
  dataIndex: 'publishedAt',
  key: 'publishedAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Version',
  dataIndex: 'version',
  key: 'version',
  width: 80,
  minWidth: 80
}, {
  title: 'Is published',
  dataIndex: 'isPublished',
  key: 'isPublished',
  width: 150,
  minWidth: 150
}, {
  title: 'Actions',
  dataIndex: 'actions',
  key: 'actions',
  width: 150,
  minWidth: 150
}]

export default defineComponent({
  name: 'VersionsTable',
  props: {
    playlistId: String
  },
  setup (props) {
    const playlistId = toRef(props, 'playlistId')
    const versionsColumns = ref(cloneDeep(VERSIONS_COLUMNS))

    const { mutate: publishUserPlaylistVersion } = useMutation(PUBLISH_USER_PLAYLIST_VERSION)
    const playlistVersionsQueryOptions = ref({ playlistId, pagination: { ...DEFAULT_PAGINATION } })
    const playlistVersionsSettings = computed(() => {
      return {
        enabled: !!playlistId.value
      }
    })
    const { result: playlistVersionsResult, loading: playlistVersionsLoading, refetch } = useQuery(LIST_PLAYLIST_VERSIONS, playlistVersionsQueryOptions, playlistVersionsSettings)
    const playlistVersionsList = useResult(playlistVersionsResult, [], data => data.getPlaylistVersions.data)
    const playlistVersionsPagination = useResult(playlistVersionsResult, [], data => data.getPlaylistVersions.pagination)

    const playlistVersionsPaginationConfig = computed(() => {
      return {
        total: playlistVersionsPagination.value?.total,
        pageSize: DEFAULT_PAGINATION.limit
      }
    })

    const publishVersion = async (playlistVersion) => {
      publishUserPlaylistVersion({ playlistId: playlistId.value, playlistVersion }).then(() => {
        refetch()
        success()
      }).catch(e => {
        error(e.message)
      })
      // console.log(publishUserPlaylistVersion)
      // console.log(playlistVersion)
    }

    return {
      versionsColumns,
      playlistVersionsList,
      playlistVersionsLoading,
      playlistVersionsPaginationConfig,
      publishVersion,
      formatDate
    }
  }
})
</script>

<style scoped>

</style>
