<template>
  <a-layout-sider :trigger="null" collapsible :collapsedWidth="0" theme="light" class="groups-sidebar bordered" width="224px">
    <a-layout class="full-height">
      <a-layout-header theme="light" style="background-color: #fff">
        <a-input-search v-model:value="searchValue" placeholder="Filter" />
      </a-layout-header>
      <a-layout-content style="display: flex; flex-direction: column; padding: 12px 16px 0px 16px; overflow-x: auto">
        <a-typography-text>Groups:</a-typography-text>
        <a-tree
            :expanded-keys="expandedKeys"
            :selectedKeys="selectedGroup"
            :auto-expand-parent="autoExpandParent"
            :tree-data="standardGroupsTree"
            showIcon
            @expand="onExpand"
            @select="onGroupSelect"
            class="groups-sidebar-tree"
        >
          <template #icon>
            <InboxOutlined/>
          </template>
          <template #title="{ title }" >
            <a-tooltip placement="top" :mouseEnterDelay=".8">
              <template #title>{{ title }}</template>
              <span v-if="title?.indexOf(searchValue) > -1">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span class="search-value">{{ searchValue }}</span>
              {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
            </span>
              <span v-else>{{ title }}</span>
            </a-tooltip>
          </template>
        </a-tree>
        <a-typography-text v-if="smartGroupsTree?.length">Smart Groups:</a-typography-text>
        <a-tree
            v-if="smartGroupsTree?.length"
            :selectedKeys="selectedGroup"
            :tree-data="smartGroupsTree"
            showIcon
            @select="onGroupSelect"
            class="groups-sidebar-tree"
        >
          <template #icon>
            <InboxOutlined/>
          </template>
          <template #title="{ title }" >
            <a-tooltip placement="top" :mouseEnterDelay=".8">
              <template #title>{{ title }}</template>
              <span v-if="title?.indexOf(searchValue) > -1">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span class="search-value">{{ searchValue }}</span>
              {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
            </span>
              <span v-else>{{ title }}</span>
            </a-tooltip>
          </template>
        </a-tree>
      </a-layout-content>

    </a-layout>
  </a-layout-sider>
</template>

<script>
import { InboxOutlined } from '@ant-design/icons-vue'
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import { GET_AVAILABLE_GROUPS } from '@/graphql/queries'
import { useQuery } from '@vue/apollo-composable'

const getParentKey = (key, tree) => {
  let parentKey

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]

    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

const groupsArrayToNormalizedList = (input) => {
  return input.map(({ id, name, parentGroupId }) => ({ key: id, title: name, parentGroupId }))
}

const groupsArrayToTreeView = (input) => {
  if (input.length === 0) return []

  const groups = input.map(({ id, name, parentGroupId }) => ({ key: id, title: name, parentGroupId, children: [], value: id }))
  const map = {}
  let node
  const roots = []

  for (let i = 0; i < groups.length; i += 1) {
    map[groups[i].key] = i
  }

  for (let i = 0; i < groups.length; i += 1) {
    node = groups[i]
    if (node.parentGroupId) {
      groups[map[node.parentGroupId]].children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

export default defineComponent({
  name: 'GroupsSidebar',
  components: {
    InboxOutlined
  },
  props: {
    workspaceId: {
      type: String
    },
    group: {
      type: String,
      default: null
    }
  },
  emits: ['group-change'],
  setup (props, { emit }) {
    const workspaceId = toRef(props, 'workspaceId')
    const enabled = computed(() => {
      return !!workspaceId.value
    })
    const filters = ref({
      workspaceId
    })
    const { result: groupsResult, loading: groupsLoading, onResult: onGroupsFetched } = useQuery(GET_AVAILABLE_GROUPS, { filters }, { fetchPolicy: 'no-cache', enabled })
    const selectTreeRefreshKey = ref('1')
    const expandedKeys = ref([])
    const searchValue = ref('')
    const group = toRef(props, 'group')
    const selectedGroup = ref(props.group ? [props.group] : [])
    const autoExpandParent = ref(true)
    const showCreateGroupModal = ref(false)
    const onExpand = keys => {
      expandedKeys.value = keys
      autoExpandParent.value = false
    }

    const getParentGroupId = () => {
      const [selectedGroupId] = selectedGroup.value
      const { parentGroupId } = dataList.value.find(({ key }) => key === selectedGroupId) || {}
      return parentGroupId
    }

    const standardGroups = computed(() => groupsResult.value?.listAllGroups?.filter(g => {
      return g.__typename === 'StandardGroupModel'
    }))

    const smartGroups = computed(() => groupsResult.value?.listAllGroups?.filter(g => {
      return g.__typename === 'SmartGroupModel'
    }))

    const groupsTree = computed(() => groupsArrayToTreeView(groupsResult.value?.listAllGroups || []))
    const standardGroupsTree = computed(() => groupsArrayToTreeView(standardGroups.value || []))
    const smartGroupsTree = computed(() => groupsArrayToTreeView(smartGroups.value || []))

    const dataList = computed(() => groupsResult.value?.listAllGroups ? groupsArrayToNormalizedList(groupsResult.value.listAllGroups) : [])
    const standardDataList = computed(() => standardGroupsTree.value ? groupsArrayToNormalizedList(standardGroupsTree.value) : [])
    const smartDataList = computed(() => smartGroupsTree.value ? groupsArrayToNormalizedList(smartGroupsTree) : [])

    const onGroupSelect = (data, { node }) => {
      selectedGroup.value = [node.key]
      if (!expandedKeys.value.find(k => k === node.key)) {
        expandedKeys.value.push(node.key)
      }
      emit('group-change', node.key)
    }

    onGroupsFetched(() => {
      selectTreeRefreshKey.value = new Date() + ''

      const isUsersGroup = !!groupsTree.value.find(({ key }) => selectedGroup.value[0] === key)

      if (!selectedGroup.value.length || !isUsersGroup) {
        const group = groupsTree.value.length ? groupsTree.value[0].key : ''
        selectedGroup.value = [props.group || group]
      }

      emit('group-change', selectedGroup.value[0])

      const parentGroupId = getParentGroupId()
      if (parentGroupId && !expandedKeys.value.includes(parentGroupId)) {
        autoExpandParent.value = true
        expandedKeys.value.push(parentGroupId)
      }
    })

    watch(searchValue, value => {
      expandedKeys.value = dataList.value.map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, groupsTree.value)
        }
        return null
      }).filter((item, i, self) => item && self.indexOf(item) === i)
      searchValue.value = value
      autoExpandParent.value = true
    })

    watch(group, newValue => {
      if (newValue) {
        selectedGroup.value = [newValue]
      }
    })

    return {
      expandedKeys,
      searchValue,
      autoExpandParent,
      standardGroupsTree,
      smartGroupsTree,
      selectedGroup,
      groupsLoading,
      showCreateGroupModal,
      selectTreeRefreshKey,
      dataList,
      standardDataList,
      smartDataList,
      onExpand,
      onGroupSelect

    }
  }

})
</script>
<style lang="less">
@import "../styles/variables.less";

.groups-sidebar {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
  .ant-layout-header {
    height: 40px;
    padding: 4px 16px;

  }

  .groups-sidebar-tree {
    .ant-tree-treenode-motion {
      width: 100%;
    }
    .ant-tree-treenode {
      width: 100%;
      padding-right: 8px;
      .ant-tree-node-content-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        .ant-tree-title {
          flex: 1;
          justify-content: space-between;
          align-items: center;
          display: flex;
        }
      }
    }
  }
}
.search-value {
  color: @primary-color;
}

</style>
