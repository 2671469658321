<template>
  <a-layout style="padding: 24px; text-align: left;">
    <a-layout-content>
      <a-input-search
          v-model:value="searchValue"
          placeholder="Input search text"
          style="width: 200px; margin-bottom: 24px"
          @change="onSearch"
      />
      <a-table
          :data-source="usersList"
          @resizeColumn="handleResizeColumn"
          :columns="columns"
          :loading="loading"
          size="small"
          :pagination="paginationConfig"
          @change="onChange"
          :customRow="customRow"
          class="table-padded">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'createdAt'">
            {{formatDate(text)}}
          </template>
          <template v-else-if="column.key === 'companyName'">
            {{text || 'Unset'}}
          </template>
          <template v-else-if="column.key  === 'firstName'">
            {{text}} {{record.lastName}}
          </template>
          <template v-else-if="column.key  === 'language'">
            {{LANGUAGES_LIST[text]}}
          </template>
          <template v-else>
            {{text}}
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent, ref, nextTick } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { cloneDeep, debounce } from 'lodash'
import { formatDate } from '@/utils'
import { LIST_USERS } from '@/graphql/queries'
import { DEFAULT_PAGINATION, LANGUAGES_LIST } from '@/constants'
import { useRouter } from 'vue-router'

const COLUMNS = [{
  title: 'Email',
  dataIndex: 'email',
  key: 'email',
  width: 200,
  minWidth: 100,
  maxWidth: 900,
  resizable: true
},
{
  title: 'Name',
  dataIndex: 'firstName',
  key: 'firstName',
  width: 200,
  minWidth: 200,
  maxWidth: 400,
  resizable: true
},
{
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 200,
  minWidth: 200,
  maxWidth: 200
},
{
  title: 'Company Name',
  dataIndex: 'companyName',
  key: 'companyName',
  width: 120,
  minWidth: 120,
  maxWidth: 120
},
{
  title: 'Language',
  dataIndex: 'language',
  key: 'language',
  width: 120,
  minWidth: 120,
  maxWidth: 120
}]

export default defineComponent({
  name: 'AccountsPage',
  setup () {
    const settings = ref({ enabled: false, fetchPolicy: 'network-only' })
    nextTick(() => {
      settings.value.enabled = true
    })
    const router = useRouter()
    const searchValue = ref('')
    const queryOptions = ref({ pagination: { ...DEFAULT_PAGINATION }, filters: { search: null } })
    const { onResult, loading } = useQuery(LIST_USERS, queryOptions, settings)
    const usersList = ref([])
    const paginationResult = ref({})
    const paginationConfig = computed(() => {
      return {
        total: paginationResult.value?.total,
        pageSize: DEFAULT_PAGINATION.limit
      }
    })
    const columns = ref(cloneDeep(COLUMNS))

    onResult(({ data }) => {
      if (data?.listUsers?.data) {
        usersList.value = data.listUsers.data
        paginationResult.value = data.listUsers.pagination
      }
    })

    const handleResizeColumn = (w, col) => {
      col.width = w
    }

    const onChange = async (pagination) => {
      queryOptions.value.pagination.offset = (pagination.current - 1) * DEFAULT_PAGINATION.limit
    }

    const onSearch = debounce(() => {
      if (searchValue.value.length > 2) {
        queryOptions.value.filters.search = searchValue.value.toLowerCase()
      } else if (searchValue.value === '') {
        queryOptions.value.filters.search = null
      }
    }, 500)

    const customRow = (record) => {
      return {
        onClick: () => {
          return router.push({ name: 'user', params: { userId: record.id } })
        }
      }
    }

    return {
      columns,
      loading,
      usersList,
      paginationConfig,
      searchValue,
      onSearch,
      LANGUAGES_LIST,
      customRow,
      formatDate,
      onChange,
      handleResizeColumn
    }
  }
})
</script>

<style lang="less">
.ant-table-row {
  cursor: pointer;
}

</style>
