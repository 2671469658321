<template>
  <a-modal v-model:visible = showMemberModal :title="`${selectedMember?.user?.firstName} ${selectedMember?.user?.lastName}`" :footer="null" width="1000px">
    <JsonViewer :value="selectedMember" copyable expanded sort theme="jv-light"/>
  </a-modal>
  <a-table
      :data-source="membersList"
      @resizeColumn="handleResizeColumn"
      :columns="columns"
      :loading="loading"
      size="small"
      style="margin-top: 8px; overflow-x: auto"
      :scroll="{ x: 600 }"
      class="table-padded">
    <template #bodyCell="{ column, text, record }">
      <template v-if="['createdAt','updatedAt'].includes(column.key)">
        {{formatDate(text)}}
      </template>
      <template v-else-if="column.key === 'user'">
        <router-link :to="{params:{userId: record?.user?.id}}">{{record?.user?.firstName}} {{record?.user?.lastName}}</router-link><InfoCircleOutlined @click="openMemberModal(record)" style="margin-left: 16px;userId"/>
      </template>
      <template v-else-if="column.key === 'role'">
        <template v-if="record.role === 'OWNER'">
          Owner
        </template>
        <a-select
            v-else
            size="small"
            :value="text"
            style="width: 100px"
            @change="(role)=>{ onChangeUserRole(role, record?.user?.id) }"
        >
          <a-select-option value="OWNER">Owner</a-select-option>
          <a-select-option value="ADMIN">Admin</a-select-option>
          <a-select-option value="MANAGER">Manager</a-select-option>
          <a-select-option value="AUTHOR">Author</a-select-option>
        </a-select>
      </template>
      <template v-else-if="column.key === 'actions'">
        <a-popconfirm title="Are you sure" @confirm="()=>{onDeleteUserRole(record?.user?.id)}" v-if="record.role !=='OWNER'">
          <a-button size="small" type="text" danger><DeleteOutlined/></a-button>
        </a-popconfirm>
      </template>
      <template v-else>
        {{text}}
      </template>
    </template>
  </a-table>
</template>

<script>
import { defineComponent, ref, toRef } from 'vue'
import { GET_WORKSPACE_MEMBERS } from '@/graphql/queries'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { cloneDeep } from 'lodash'
import { formatDate, success } from '@/utils'
import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { DELETE_WORKSPACE_MEMBER_ROLE, SET_WORKSPACE_MEMBER_ROLE } from '@/graphql/mutations'
import { useRouter } from 'vue-router'

const COLUMNS = [{
  title: 'Name',
  dataIndex: 'user',
  key: 'user',
  resizable: true,
  ellipsis: true,
  width: 200,
  maxWidth: 400,
  minWidth: 50,
  fixed: 'left'
}, {
  title: 'Role',
  dataIndex: 'role',
  key: 'role',
  width: 100,
  maxWidth: 200,
  minWidth: 100
}, {
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Updated At',
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Actions',
  dataIndex: 'actions',
  key: 'actions',
  width: 80,
  minWidth: 80
}]

export default defineComponent({
  name: 'MembersView',
  components: {
    InfoCircleOutlined,
    DeleteOutlined
  },
  props: {
    groupId: {
      type: String
    },
    userId: {
      type: String
    },
    workspaceId: {
      type: String
    }
  },
  setup (props) {
    const workspaceId = toRef(props, 'workspaceId')
    const userId = toRef(props, 'userId')
    const router = useRouter()
    const { result: membersResult, loading, refetch } = useQuery(GET_WORKSPACE_MEMBERS, { workspaceId }, { fetchPolicy: 'no-cache' })
    const { mutate: changeUserRole } = useMutation(SET_WORKSPACE_MEMBER_ROLE)
    const { mutate: deleteUserRole } = useMutation(DELETE_WORKSPACE_MEMBER_ROLE)

    const membersList = useResult(membersResult, [], data => data.listWorkspaceMemberRolesByWorkspaceId)
    const showMemberModal = ref(false)
    const selectedMember = ref(null)
    const columns = ref(cloneDeep(COLUMNS))

    const handleResizeColumn = (w, col) => {
      col.width = w
    }

    const openMemberModal = (record) => {
      selectedMember.value = record
      showMemberModal.value = true
    }

    const onChangeUserRole = async (role, userId) => {
      const input = {
        userId,
        role,
        ...(!['OWNER', 'ADMIN'].includes(role) && { restrictions: [] })
      }
      changeUserRole({ workspaceId: workspaceId.value, input }).then(() => {
        success()
        refetch()
      })
    }

    const onDeleteUserRole = async (_userId) => {
      deleteUserRole({ workspaceId: workspaceId.value, userId: _userId }).then(() => {
        success()
        if (_userId === userId.value) {
          return router.push({ name: 'user' })
        }
        refetch()
      })
    }

    return {
      columns,
      loading,
      membersList,
      selectedMember,
      showMemberModal,
      openMemberModal,
      onChangeUserRole,
      onDeleteUserRole,
      formatDate,
      handleResizeColumn
    }
  }
})
</script>

<style scoped>

</style>
