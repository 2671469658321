import { createStore } from 'vuex'
import auth from './auth'

export default createStore({
  state: {
    showGlobalSettings: false,
    globalSettingsActiveTab: 'profile'
  },
  actions: {
    openGlobalSettings ({ commit }, tab) {
      commit('OPEN_GLOBAL_SETTINGS', tab)
    },
    closeGlobalSettings ({ commit }) {
      commit('CLOSE_GLOBAL_SETTINGS')
    }
  },
  getters: {
    showGlobalSettings: state => state.showGlobalSettings,
    globalSettingsActiveTab: state => state.globalSettingsActiveTab
  },
  mutations: {
    OPEN_GLOBAL_SETTINGS (state, tab) {
      state.globalSettingsActiveTab = tab
      state.showGlobalSettings = true
    },
    CLOSE_GLOBAL_SETTINGS (state) {
      state.showGlobalSettings = false
    }
  },
  modules: {
    auth
  }
})
